<template>
  <!-- <html>
    <head>
      <title>Invoice MetalXperts LLP</title>
    </head>
    <body> -->
  <div class="body bg-white" id="sjInovice" style="backgr">
    <div
      class="row d-flex flex-row m-0 mt-14 g-12 justify-content-center align-items-center"
    >
      <div class="row bg-black">
        <div class="d-flex justify-content-start">
          <v-btn
            class="btn btn-warning btn-sm d-flex justify-content-between align-items-center bg-black"
            @click="printDoc"
            ><i class="bi bi-file-earmark-arrow-down-fill"></i>Print</v-btn
          >
          <v-btn
            class="btn btn-warning btn-sm d-flex justify-content-between align-items-center bg-black ms-3"
            @click="showList"
            ><i class="bi bi-file-earmark-arrow-down-fill"></i>Download</v-btn
          >
        </div>
      </div>
    </div>

    <div class="p-20" ref="invoiceRef">
      <div class="row">
        <div class="col-md-3 fv-row"></div>
        <div
          class="border border-3 border-dark col-md-6 fv-row"
          style="height: 100%"
        >
          <div class="row">
            <div class="d-flex justify-content-between" style="height: 100%">
              <div class="d-flex align-items-center pl-20">
                <div class="ms-3">
                  <p class="fs-3 fw-bolder">MetalXperts (India)LLP</p>
                  <div class="fs-6 pt-1 lh-3">
                    <p>
                      Survey No. 799 At. Chhatral,Tal.Kalol,Chhatral(CT)-382729
                      Dist:Gandhinagar,<br />gujarat - India
                    </p>
                    <p>
                      <span class="fw-bolder">Contact No:</span
                      ><span class="fw-bolder">Email:</span>info@metalxperts.com
                      <span class="fw-bolder">Website</span
                      >www.metalxperts.com<br />
                      <span class="fw-bolder">GSTIN:</span>24ABFFM7697B1Z4
                      <span class="fw-bolder">PAN:</span>ABFFM7697B
                    </p>
                  </div>
                </div>
              </div>
              <div class="text-start">
                <table
                  class="border-start border-dark border-2 bg-white"
                  style="width: 100%; height: 100%"
                >
                  <tr class="bottom text-center">
                    <th colspan="2" class="invoice">Stock Journal Voucher</th>
                  </tr>
                  <tr class="bottom text-center">
                    <th colspan="2" class="invoice">Original</th>
                  </tr>
                  <tr class="bottom">
                    <td>SJV NO</td>
                    <td><span>: </span></td>
                  </tr>
                  <tr class="bottom">
                    <td>Reason</td>
                    <td><span>: Stock Transfer</span></td>
                  </tr>
                  <tr class="bottom text-center">
                    <td>One warehouse to another warehouse</td>
                  </tr>
                  <tr class="bottom">
                    <td>Transport</td>
                    <td><span>: No</span></td>
                  </tr>
                </table>
              </div>
            </div>
            <!-- <hr class="line" /> -->
            <!-- <div class="d-flex justify-content-between p-0 mt-0">
          <div class="bill_sec">
            <p class="p-10">
              We are pleased to confirm our requirement of the following
              product:
            </p>
          </div>
        </div> -->
          </div>
          <div class="card-body pt-0 pb-0">
            <div class="table-responsive" style="widht: 100%; height: 70%">
              <!--begin::Table-->
              <table
                class="table table-row-gray-300 table-bordered align-middle bg-white gs-0 gy-4"
              >
                <!--begin::Table head-->
                <thead>
                  <tr class="fw-bolder text-muted fs-5 bg-light">
                    <th
                      class="ps-4 min-w-10px rounded-start"
                      style="border-right: 1px solid #111 !important"
                    >
                      Outward Warehouse
                    </th>
                    <th
                      class="ps-4 min-w-10px rounded-start"
                      style="
                        border-right: 1px solid #111 !important;
                        border-left: 0px !important;
                      "
                    >
                      Inward Warehouse
                    </th>
                    <th class="ps-4 min-w-50px">Dispatch Details</th>
                  </tr>
                </thead>
                <!--end::Table head-->

                <!--begin::Table body-->
                <tbody class="bg-white">
                  <!-- <template> -->
                  <tr class="bg-white">
                    <td
                      class="bg-white p-4"
                      style="border-right: 1px solid #111 !important"
                    >
                      <span class="fw-bolder fs-5"
                        >STEEL HOUSE METALXPERTS
                      </span>
                      <p>
                        3rd Floor, Mrudul Tower, Nr. H.K. House, Ashram road
                      </p>
                      <p>Ahmedabad - 380009,Dist: Ahmedabad</p>
                      <p>Gujarat - India</p>
                      <p>
                        <span class="fw-bolder fs-5"> State Code</span>: 24 ,
                        <span class="fw-bolder fs-5"> GSTIN</span>:
                        24AAQFS1636J1ZX,
                        <span class="fw-bolder fs-5">PAN</span>: AAQFS1636J
                      </p>
                      <span
                        ><span class="fw-bolder fs-5">Contact Person</span>: Mr.
                        Hitesh Shah</span
                      >
                      <p>M: 9825015953</p>
                    </td>
                    <td
                      class="bg-white p-4"
                      style="border-right: 1px solid #111 !important"
                    >
                      <span class="fw-bolder fs-5"
                        >METALXPERTS (INDIA) LLP
                      </span>
                      <p>Survey No. 799 At. Chhatral, Tal. Kalol</p>
                      <p>Chhatral (CT) - 382729,Dist: Gandhinagar</p>
                      <p>Gujarat - India</p>
                      <p>
                        <span class="fw-bolder fs-5"> State Code</span>: 24 ,
                        <span class="fw-bolder fs-5"> GSTIN</span>:
                        24AAQFS1636J1ZX,
                        <span class="fw-bolder fs-5">PAN</span>: ABFFM7697B
                      </p>
                      <span
                        ><span class="fw-bolder fs-5">Contact Person</span>: Mr.
                        Amar Gaunjhu</span
                      >
                      <p>M: 8051638051</p>
                    </td>
                    <td
                      class="p-0 m-0 bg-white"
                      style="border-top: 0px !important"
                    >
                      <!-- <p>Transport Name:</p>
                      <el-divider />
                      <p>Ref. No/ Vehicle No:</p>
                      <el-divider />
                      <p>Bill of Landing/LR/RR No:</p>
                      <el-divider />
                      <p>Bill of Landing/LR/RR Date:</p>
                      <el-divider /> -->
                      <table
                        class="border-start bg-white"
                        style="width: 100%; height: 100%"
                      >
                        <tr
                          class="bottom m-0 bg-white"
                          style="border: 0px !important"
                        >
                          <td class="m-0" style="border-top: 0px !important">
                            Transport Name:
                          </td>
                          <!-- <td
                            class="m-0"
                            style="padding-right: 10px !important"
                          >
                            <span>3333 </span>
                          </td> -->
                        </tr>
                        <tr class="bottom">
                          <td class="m-0">Ref. No/ Vehicle No:</td>
                          <td><span> </span></td>
                        </tr>
                        <tr class="bottom">
                          <td class="m-0">Bill of Landing/LR/RR No:</td>
                          <td><span> </span></td>
                        </tr>
                        <tr class="bottom">
                          <td class="m-0">Bill of Landing/LR/RR Date:</td>
                          <td><span> </span></td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <!-- </template> -->
                </tbody>
                <!--end::Table body-->
              </table>
              <!--end::Table-->
            </div>
          </div>
          <div class="card-body pt-0 pb-0">
            <div class="table-responsive" style="widht: 100%; height: 70%">
              <!--begin::Table-->
              <table
                class="table table-bordered bg-white align-middle gs-0 gy-4"
              >
                <!--begin::Table head-->
                <thead>
                  <tr class="fw-bolder text-muted fs-5 bg-light">
                    <th
                      class="ps-4 min-w-10px rounded-start"
                      style="border-right: 0.5px solid #111 !important"
                    >
                      S.R. No.
                    </th>
                    <th
                      class="ps-4 min-w-100px"
                      style="border-right: 0.5px solid #111 !important"
                    >
                      Description
                    </th>
                    <th
                      class="ps-4 min-w-50px"
                      style="border-right: 0.5px solid #111 !important"
                    >
                      Quantity
                    </th>
                    <th
                      class="min-w-12 5px"
                      style="border-right: 0.5px solid #111 !important"
                    >
                      UOM
                    </th>
                    <th
                      class="min-w-30px"
                      style="border-right: 0.5px solid #111 !important"
                    >
                      Rate
                    </th>
                    <th class="min-w-140px">Taxable Ammount</th>
                  </tr>
                </thead>
                <!--end::Table head-->

                <!--begin::Table body-->
                <tbody>
                  <template v-if="!myData.length">
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td colspan="7" class="text-center">Data Not Found!</td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </template>
                  <template v-else v-for="(item, index) in myData" :key="index">
                    <tr>
                      <td style="border-right: 0.5px solid #111 !important">
                        <span
                          class="text-dark fw-bolder d-block mb-1 fs-6 ps-4"
                          >{{ item.srno }}</span
                        >
                      </td>

                      <td style="border-right: 0.5px solid #111 !important">
                        <span class="text-dark fw-bolder d-block mb-1 fs-6">{{
                          item.description
                        }}</span>
                      </td>

                      <td style="border-right: 0.5px solid #111 !important">
                        <span class="text-dark fw-bolder d-block mb-1 fs-6">{{
                          item.quantity
                        }}</span>
                        <span
                          class="text-muted fw-bold text-muted d-block fs-7"
                        ></span>
                      </td>

                      <td style="border-right: 0.5px solid #111 !important">
                        <span class="text-dark fw-bolder d-block mb-1 fs-6">{{
                          item.uom
                        }}</span>
                      </td>

                      <td style="border-right: 0.5px solid #111 !important">
                        <span class="text-dark fw-bolder d-block mb-1 fs-6">{{
                          item.rate
                        }}</span>
                      </td>

                      <td>
                        <span class="text-dark fw-bolder d-block mb-1 fs-6">{{
                          item.taxable_ammount
                        }}</span>
                      </td>

                      <!-- <td>
                      <span class="text-dark fw-bolder d-block mb-1 fs-6">{{
                        item.product_batch_no
                      }}</span>
                    </td>

                    <td>
                      <span class="text-dark fw-bolder d-block mb-1 fs-6">{{
                        item.stock_remark
                      }}</span>
                    </td> -->
                    </tr>
                  </template>
                </tbody>
                <tfoot>
                  <tr>
                    <th
                      colspan="5"
                      class="text-dark fw-bolder mb-1 fs-6 text-end pe-20"
                      style="border-right: 0.5px solid #111 !important"
                    >
                      Total
                    </th>
                    <th class="text-dark fw-bolder fs-6 text-center">
                      {{ voucharTotal }}
                    </th>
                  </tr>
                  <tr class="" style="height: 100px">
                    <td class="border2" colspan="9"></td>
                  </tr>
                </tfoot>
                <!--end::Table body-->
              </table>
              <!--end::Table-->
            </div>
          </div>
          <!-- <div class="pt-4">
          <el-table
            :data="myData"
            border
            :default-sort="{ prop: 'srno', order: 'ascending' }"
            style="width: 100%"
          >
            <el-table-column
              prop="srno"
              label="S.R. No."
              width="180"
              sortable
            />
            <el-table-column prop="description" label="Description" sortable />
            <el-table-column
              prop="quantity"
              width="150"
              label="Qty."
              sortable
            />
            <el-table-column prop="uom" width="150" label="UOM" sortable />
            <el-table-column prop="rate" width="250" label="Rate" sortable />
            <el-table-column
              prop="taxable_ammount"
              width="180"
              label="Taxable Ammount"
              sortable
            />
          </el-table>
          <el-table-footer
            v-show="false"
            :data="summery"
            :ref="footerTableRef"
          ></el-table-footer>
        </div> -->
          <!-- <div class="row pt-0">
          <table class="border border-dark border-2 mt-4">
            <tr class="mt-20 border border-dark border-2" height="50px">
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 5%"
              >
                Sr.No
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 10%"
              >
                SKU
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 30%"
              >
                Product Description
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 20%"
              >
                Make
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 5%"
              >
                Remarks
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 5%"
              >
                Quantity
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 7.33%"
              >
                UOM
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 9.33%"
              >
                Rate
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 8.33%"
              >
                Amount
              </th>
            </tr>
            <tr class="border border-dark border-2">
              <td class="border1 text-centre">1</td>
              <td class="border text-centre"></td>
              <td class="border text-centre"></td>
              <td class="border text-centre"></td>
              <td class="border text-centre"></td>
              <td class="border text-centre"></td>
              <td class="border text-centre"></td>
              <td class="border text-centre"></td>
              <td class="border1 text-centre"></td>
            </tr>
            <tr>
              <td class="border1 text_right" colspan="5">Total</td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border1"></td>
            </tr>
            <tr>
              <td class="border1 text_right" colspan="7"></td>
              <td class="border">Round Up</td>
              <td class="border1"></td>
            </tr>
            <tr>
              <td class="border1 text_right" colspan="7"></td>
              <td class="border">Grand Total</td>
              <td class="border1"></td>
            </tr>
            <tr height="70px">
              <td class="border1 table_text_left" colspan="9">Remarks:</td>
            </tr>
            <tr class="" height="300px">
              <td class="border2" colspan="9"></td>
            </tr>
          </table>
          <table>
            <tr class="" height="100px">
              <td
                class="bottom table_text_align"
                colspan="3"
                rowspan="2"
                width="33.3333%"
              >
                Prepared By
              </td>
              <td
                class="bottom left table_text_align"
                colspan="3"
                rowspan="2"
                width="33.3333%"
              >
                Checked By
              </td>
              <td class="left table_text_right" colspan="3">
                For MetalXperts(india)LLP
              </td>
            </tr>
            <tr class="">
              <td
                class="bottom left table_text_right"
                colspan="3"
                width="33.3333%"
              >
                Authorised Signatory
              </td>
            </tr>
          </table>
        </div> 
        -->

          <!-- <div class="bill_total_wrap">
          <div class="bill_sec p-10">
            <span>
              <p class="">
                E & O.E <span class="alignright">Page No 1 of 1</span>
              </p>
            </span>
          </div>
        </div> -->
          <table class="table-bordered bg-white" style="width: 100%">
            <tr class="" height="100px">
              <td
                class="bottom table_text_align"
                colspan="3"
                rowspan="2"
                width="33.3333%"
              >
                Prepared By
              </td>
              <td
                class="bottom left table_text_align"
                colspan="3"
                rowspan="2"
                width="33.3333%"
              >
                Checked By
              </td>
              <td class="left table_text_right" colspan="3">
                For MetalXperts(india)LLP
              </td>
            </tr>
            <tr class="">
              <td
                class="bottom left table_text_right"
                colspan="3"
                width="33.3333%"
              >
                Authorised Signatory
              </td>
            </tr>
          </table>
        </div>
        <div class="col-md-3 fv-row"></div>
      </div>
    </div>
  </div>
  <!-- </body>
  </html> -->
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
// import { ElTable } from "element-plus";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import { useStore } from "vuex";
import html2pdf from "html2pdf.js";

export default defineComponent({
  props: {
    id: { required: true },
  },
  name: "invoice-html",
  // components: {
  //   VueHtml2pdf,
  // },
  setup(props, root) {
    // const footerTableRef = ref<InstanceType<typeof ElTable>>();
    // const footerTableRef = ref(null);
    var ids = ref(props.id);
    const footerTableRef = ref(null);
    const table = ref(null);
    const store = useStore();
    const voucharTotal = ref([]);
    const uom_data = ref([]);
    const invoiceRef = ref(null);
    // const invoiceRef = (ref < null) | (HTMLElement > null);

    getSJDetails();
    getUOM();
    // footerTableRef.value().init(table.value());

    const myData = ref([
      // {
      //   srno: 1,
      //   description: "JSW Steel",
      //   quantity: 4,
      //   uom: "Mt",
      //   rate: 2,
      //   taxable_ammount: 2000,
      // },
      // {
      //   srno: 2,
      //   description: "JSW Steel",
      //   quantity: 4,
      //   uom: "Mt",
      //   rate: 2,
      //   taxable_ammount: 2000,
      // },
      // {
      //   srno: 3,
      //   description: "JSW Steel",
      //   quantity: 4,
      //   uom: "Mt",
      //   rate: 2,
      //   taxable_ammount: 2000,
      // },
    ]);

    const footerData = ref([
      {
        label: "Total",
        data: {
          quantity: 13414,
        },
      },
    ]);

    async function getSJDetails() {
      console.log("sjDetails.." + props.id);
      debugger;
      let params = {
        page: 1,
        records_per_page: 100,
        stock_journal_voucher_id: parseInt(props.id),
      };
      await store
        .dispatch(ActionsFi.CUST_GET_SJ_INVOICE_DETAILS, params)
        .then(({ data }) => {
          debugger;
          voucharTotal.value = data[0].stock_journal_voucher.total_amount;
          let values = data.map((item, index) => {
            let sjRow = {
              // srno: item.stock_journal_voucher.stock_journal_voucher_no,
              srno: index + 1,
              description: item.product_name,
              quantity: item.product_qunatity,
              uom: uom_data.value.filter((uom) => {
                return uom.uom_id === item.uom_id;
              })[0].uom_display_name,
              rate: item.product_rate,
              taxable_ammount: item.stock_journal_voucher.total_amount,
            };
            return sjRow;
          });
          myData.value = values;
          // location.reload();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    async function getUOM() {
      await store
        .dispatch(ActionsFi.CUST_GET_UOM)
        .then(({ data }) => {
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    const summery = () => {
      return [footerData];
    };
    const showList = () => {
      // console.log("hi");
      html2pdf(invoiceRef.value, {
        margin: 1,
        filename: "invoice.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
      // var frame = document.getElementById("sjInovice");
      // frame.print();
    };
    const printDoc = () => {
      // invoiceRef.value.print();
      var newstr = invoiceRef.value.innerHTML;
      var oldstr = document.body.innerHTML;

      document.body.innerHTML = newstr;
      window.print();
      document.body.innerHTML = oldstr;
      window.location.reload();
    };
    // printDoc(true);

    return {
      myData,
      footerData,
      footerTableRef,
      ids,
      uom_data,
      invoiceRef,
      voucharTotal,
      showList,
      summery,
      getSJDetails,
      printDoc,
    };
  },
});
</script>
<style type="text/css" scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;400;900&display=swap");

:root {
  --primary: #0000ff;
  --secondary: #3d3d3d;
  --white: #fff;
  --Black: #000;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background: var(--secondary);
  padding: 50px;
  color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.bold {
  font-weight: 900;
}

.light {
  font-weight: 100;
}

.wrapper {
  background: var(--white);
  padding: 30px;
}

.table-bordered th,
.table-bordered tr,
.table-bordered td {
  border: 1px solid #111 !important;
  border-left: 0px !important;
  border-right: 0px !important;
  background-color: white !important;
}
.invoice_wrapper {
  border: 3px solid var(--Black);
  width: 100%;
  max-width: 100%;
}

.invoice_wrapper .header .logo_invoice_wrap,
.invoice_wrapper .header .bill_total_wrap {
  display: flex;
  justify-content: space-between;
  padding: 0px;
}

.invoice_wrapper .header .logo_sec {
  display: flex;
  align-items: center;
}

.invoice_wrapper .header .logo_sec .title_wrap {
  margin-left: 5px;
}

.invoice_wrapper .header .logo_sec .title_wrap .title {
  font-size: 24px;
  color: var(--Black);
}

.invoice_wrapper .header .logo_sec .title_wrap .sub_title {
  font-size: 13px;
  padding-top: 2px;
  line-height: 1.3;
}

.invoice_wrapper .header .invoice_sec,
.invoice_wrapper .header .bill_total_wrap .total_wrap {
  text-align: left;
  width: 33%;
}

.invoice_wrapper .header .invoice_sec .invoice {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin: 20px;
}

.invoice_wrapper .header .invoice_sec .invoice_no,
.invoice_wrapper .header .invoice_sec .date {
  display: flex;
  width: 100%;
}

.invoice_wrapper .header .invoice_sec .invoice_no span:first-child,
.invoice_wrapper .header .invoice_sec .date span:first-child {
  width: 100px;
  text-align: left;
}

.invoice_wrapper .header .invoice_sec .invoice_no span:last-child,
.invoice_wrapper .header .invoice_sec .date span:last-child {
  width: calc(100% - 70px);
}

.invoice_wrapper .header .bill_total_wrap .total_wrap .price,
.invoice_wrapper .header .bill_total_wrap .bill_sec .name {
  color: var(--primary);
  font-size: 20px;
}

.invoice_wrapper .body .main_table .table_header {
  background: var(--primary);
}

.invoice_wrapper .body .main_table .table_header .row {
  color: var(--white);
  font-size: 18px;
  border-bottom: 0px;
}

.invoice_wrapper .body .main_table .row {
  display: flex;
  border-bottom: 1px solid var(--secondary);
}

.invoice_wrapper .body .main_table .row .col {
  padding: 10px;
}
.invoice_wrapper .body .main_table .row .col_no {
  width: 5%;
}
.invoice_wrapper .body .main_table .row .col_des {
  width: 45%;
}
.invoice_wrapper .body .main_table .row .col_price {
  width: 20%;
  text-align: center;
}
.invoice_wrapper .body .main_table .row .col_qty {
  width: 10%;
  text-align: center;
}
.invoice_wrapper .body .main_table .row .col_total {
  width: 20%;
  text-align: right;
}

.invoice_wrapper .body .paymethod_grandtotal_wrap {
  display: flex;
  justify-content: space-between;
  padding: 5px 0 30px;
  align-items: flex-end;
}

.invoice_wrapper .body .paymethod_grandtotal_wrap .paymethod_sec {
  padding-left: 30px;
}

.invoice_wrapper .body .paymethod_grandtotal_wrap .grandtotal_sec {
  width: 30%;
}

.invoice_wrapper .body .paymethod_grandtotal_wrap .grandtotal_sec p {
  display: flex;
  width: 100%;
  padding-bottom: 5px;
}

.invoice_wrapper .body .paymethod_grandtotal_wrap .grandtotal_sec p span {
  padding: 0 10px;
}

.invoice_wrapper
  .body
  .paymethod_grandtotal_wrap
  .grandtotal_sec
  p
  span:first-child {
  width: 60%;
}

.invoice_wrapper
  .body
  .paymethod_grandtotal_wrap
  .grandtotal_sec
  p
  span:last-child {
  width: 40%;
  text-align: right;
}

.invoice_wrapper
  .body
  .paymethod_grandtotal_wrap
  .grandtotal_sec
  p:last-child
  span {
  background: var(--primary);
  padding: 10px;
  color: #fff;
}

.invoice_wrapper .footer {
  padding: 30px;
}

.invoice_wrapper .footer > p {
  color: var(--primary);
  text-decoration: underline;
  font-size: 18px;
  padding-bottom: 5px;
}

.invoice_wrapper .footer .terms .tc {
  font-size: 16px;
}
.bold {
  font-weight: bold;
}

.pb-4 {
  padding-bottom: 6px;
}
.pl-5 {
  padding-left: 5px;
}
.vl {
  border-left: 0.5px solid black;
  height: 150px;
}
.p-10 {
  padding: 10px;
}
.pl-20 {
  padding-left: 20px;
}
.f-14 {
  font-size: 14px;
}

.bottom {
  border-bottom: 1px solid black;
}
.left {
  border-left: 1px solid black;
}
.text_right {
  text-align: right;
}
.table_text_align {
  text-align: center;
  vertical-align: text-top;
}
.table_text_right {
  text-align: right;
  vertical-align: text-top;
}
.table_text_left {
  text-align: left;
  vertical-align: text-top;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}
.text-centre {
  text-align: center;
}
.line {
  border-top: 1px solid black;
}
</style>
